<template>
  <div class="manage-cashier">
    <manage-tabs class="pb-3"></manage-tabs>
    <filter-carts :filter="filter" @filter="filterData" />
    <list-carts
      v-loading="callingAPI"
      :data="dishCarts"
      @update="handleUpdateReceipt"
    />
    <el-row type="flex" align="middle" justify="end" class="pagination mt-5">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      />
    </el-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { getReceipts, updateReceipt } from '@/services/receipt'
import ManageTabs from './components/Tabs.vue'
import FilterCarts from './components/cashier/Filter.vue'
import ListCarts from './components/cashier/ListCarts.vue'

export default {
  name: 'ManageCashier',
  components: {
    ManageTabs,
    FilterCarts,
    ListCarts
  },
  data() {
    return {
      callingAPI: false,
      page: 1,
      total: 100,
      limit: 10,
      dishCarts: [],
      filter: {
        placeId: '',
        status: '',
        createdAt: dayjs().format('YYYY-MM-DDT00:00:00'),
        searchText: ''
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      const p = {
        page: this.page,
        per_page: this.limit,
        search_text: this.filter.searchText,
        locale: this.language,
        place_ids: this.filter.placeId,
        executed_status: this.$route.query.status || '',
        created: this.filter.createdAt
      }
      if (this.filter.status) {
        p.status = this.filter.status
      }
      return p
    }
  },
  watch: {
    '$route.query.status': 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    filterData(data) {
      if (data) {
        this.filter = data
      }
      this.loadData()
    },
    loadData() {
      this.callingAPI = true
      getReceipts(this.params).then((response) => {
        this.callingAPI = false
        this.dishCarts = response.result
        this.total = response.total
      }).catch(() => {
        this.dishCarts = []
        this.callingAPI = false
      })
    },

    handleUpdateReceipt(data) {
      this.callingAPI = true
      updateReceipt(data).then(() => {
        this.loadData()
        setTimeout(() => {
          this.$notify.success({
            title: 'Thông báo !',
            message: 'Đổi trạng thái thành công !'
          })
        }, 500)
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },

    handleChangeSize(size) {
      this.limit = size
      this.loadData()
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
